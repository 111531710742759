<template>
  <div class="ss" :class="{'editing': editing}">
    <div class="input-group input-group-sm">
      <input type="text" class="settlement-input form-control form-control-sm"
             placeholder="Начните вводить название населённого пункта"
             @focus="editing = true" @focusout="editing = false" @click="fetchQuery" @input="fetchQuery"
             v-model.trim="query">
    </div>

    <ul class="ss__dropdown" v-if="options.length > 0">
      <OptionRow :query="query" :option="option" @chosen="handleChosen"
                 :key="option.Id" v-for="option in options"/>
    </ul>
  </div>
</template>

<script>
  import OptionRow from '@/components/SimaSettlementSelector/OptionRow'
  import Api from '@/api'
  import debounce from 'lodash/debounce'
  import {ACTION_SETTLEMENT_EDITOR, logFeatureUse} from '@/util/ga'

  function formatName(option) {
    if(option.Name === option.Region) {
      return option.Name
    }

    return `${option.Name} (${option.Region})`
  }

  export default {
    name: 'SimaSettlementSelector',
    components: {OptionRow},

    props: {
      id: {
        required: true,
        type: Number,
      }
    },

    data() {
      return {
        query: '',

        options: [],

        editing: false,
      }
    },

    created() {
      if(!this.id) {
        return
      }

      Api.get(`/Settlements/${this.id}`).then(r => {
        this.query = formatName(r.data)
      })
    },

    watch: {
      id(newVal) {
        if(!newVal) {
          return
        }

        Api.get(`/Settlements/${newVal}`).then(r => {
          this.query = formatName(r.data)
        })
      }
    },

    methods: {
      fetchQuery: debounce(function() {
        this.editing = true

        if (this.query.length < 3) {
          this.options = []
          return
        }

        Api.get(`/Settlements`, {params: {name: this.query}}).then(r => {
          this.options = r.data
        }).catch(e => {
          alert(e.message)
        })
      }, 200),

      clear() {
        this.query = ''
        this.options = []
        this.$emit('updated')
      },

      handleChosen(option) {
        this.query = formatName(option)
        this.options = []

        this.$emit('updated', option.Id)

        logFeatureUse(ACTION_SETTLEMENT_EDITOR)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "src/styles/variables";
  @import "~bootstrap/scss/mixins";

  .ss {
    position: relative;
  }

  .ss__dropdown {
    position: absolute;
    z-index: 100;
    background: #fff;
    top: calc(2rem + #{$input-btn-focus-width});
    left: 0;
    width: 90vw;
    max-width: 90vw;
    font-size: 12px;
    list-style: none;
    margin: 0;
    padding: map-get($spacers, 1) 0 map-get($spacers, 2);

    display: grid;

    grid-template-columns: auto;

    box-shadow: $box-shadow;

    @include media-breakpoint-up(md) {
      width: 50vw;
      grid-template-columns: repeat(3, auto);

    }
  }

  .settlement-input {
    cursor: pointer;
  }

  .editing .settlement-input {
    cursor: text;
  }
</style>
