<template>
  <li class="list-group-item">
    <div class="d-flex align-items-center justify-content-between">
      <div>
        <div class="font-weight-bold">{{item.DisplayName}}</div>
        <div class="text-muted"><small>{{item.Address}}</small></div>
      </div>
      <div>
        <fa icon="trash-alt" size="2x" class="btn-remove" @click="$emit('delete')"/>
      </div>
    </div>
  </li>
</template>

<script>
  export default {
    name: 'PointItem',
    props: ['item'],
  }
</script>

<style lang="scss" scoped>
  @import "src/styles/variables";

  .btn-remove {
    color: $secondary;
    cursor: pointer;

    &:hover {
      color: $danger;
    }
  }
</style>
