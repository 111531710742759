<template>
  <Modal ref="modal" title="Отчёт о расхождениях в брони" size="lg" no-padding scrollable v-if="show" @closed="close">
    <template v-slot:body>
      <table class="table text-center" v-if="report.Items">
        <thead>
          <tr>
            <th>В брони</th>
            <th></th>
            <th>В заказе</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="i in report.Items" :key="`report-item-${i.ArticleNumber}`">
            <td>
              <span :class="{'exceeding': i.ReservedVolume > i.OrderedVolume}">{{i.ReservedVolume}}</span>
            </td>
            <td>
              <small class="text-muted">{{i.ArticleNumber}}</small>
            </td>
            <td>
              <span :class="{'exceeding': i.OrderedVolume > i.ReservedVolume}">{{i.OrderedVolume}}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <template v-slot:footer>
      <div class="spinner-border mr-2" role="status" v-if="loading"></div>

      <div class="text-danger" v-if="error">{{ error }}</div>

      <button type="button" class="btn btn-secondary" data-dismiss="modal">Закрыть</button>
    </template>
  </Modal>
</template>

<script>
  import Modal from './Modal'
  import Api from '@/api'

  export default {
    name: "ReservationDiscrepanciesDialog",

    components: {Modal},

    props: ['id'],

    data() {
      return {
        show: false,

        loading: false,
        error: null,

        report: {}
      }
    },

    methods: {
      open(id) {
        this.show = true

        this.loading = true
        this.error = null
        Api.get(`/Reservations/${id}/Discrepancies`).then(r => {
          this.loading = false
          this.report = r.data
        }).catch(e => {
          this.loading = false
          this.error = e.message
        })
      },

      close() {
        this.show = false
        this.report = {};

        this.$emit('closed')
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import "../styles/variables";

  .exceeding {
    font-weight: bold;
    color: $danger;
  }
</style>