<template>
  <Modal title="Отчёт об отправке товаров в корзину" size="lg" ref="modal" v-if="show" @closed="close">
    <template v-slot:body>
      <p>По разным причинам в корзину не отправлены следующие товары:</p>
      <dl class="row">
        <template v-for="bi in bounced">
          <dt class="col-2" :key="`dt-${bi.ArticleId}`">{{bi.ArticleId}}</dt>
          <dd class="col" :key="`dd-${bi.ArticleId}`">{{bi.Reason}}</dd>
        </template>
      </dl>
      <hr>
      <p>В корзину были отправлены следующие артикулы (включая не попавшие, указанные выше):</p>
      <div class="row">
        <template v-for="bi in submitted">
          <div class="col-2" :class="{'text-danger': isBounced(bi)}" :key="`dt-${bi.ArticleId}`"><strong>{{bi.ArticleId}}</strong> ({{bi.Volume}})</div>
        </template>
      </div>
    </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/Modal'
  import find from 'lodash/find'
  import sortBy from 'lodash/sortBy'

  export default {
    name: 'FillCartReport',
    components: {Modal},
    data() {
      return {
        show: false,
        report: {
          SubmittedItems: [],
          BouncedItems: [],
        }
      }
    },

    computed: {
      submitted() {
        return sortBy(this.report.SubmittedItems, 'ArticleId')
      },
      bounced() {
        return sortBy(this.report.BouncedItems, 'ArticleId')
      }
    },

    methods: {
      open(report) {
        this.show = true
        this.report = report
      },

      close() {
        this.show = false
        this.$emit('closed')
      },

      isBounced(item) {
        return !!find(this.report.BouncedItems, {ArticleId: item.ArticleId})
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
