<template>
  <li>
    <div class="userlist__item-container input-group input-group-sm">
      <div class="userlist__username input-group-prepend input-group-text flex-nowrap"
           :class="{'userlist__username-active': participant.Id === cartId}">
        <span class="userlist__idx">{{idx + 1}}.</span>
        <router-link :to="{ name: 'cart', params: { id: purchase.Id, uid: participant.Id } }">
          {{participant.DisplayName}}
        </router-link>
      </div>
      <div class="input-group-append" v-if="purchase.IsManaged">
        <template v-if="showAssemblyShipping">
          <button type="button" class="btn"
                  :class="{ 'btn-success': participant.IsAssembled, 'btn-outline-secondary': !participant.IsAssembled }"
                  @click="handleIsAssembled">
            <span class="d-md-none"><fa icon="box-open"/></span>
            <span class="d-none d-md-inline">собрано</span>
          </button>
          <button type="button" class="btn"
                  :class="{ 'btn-success': participant.IsShipped, 'btn-outline-secondary': !participant.IsShipped }"
                  @click="handleIsShipped">
            <span class="d-md-none"><fa icon="shipping-fast"/></span>
            <span class="d-none d-md-inline">отдано</span>
          </button>
        </template>
        <a class="btn btn-outline-secondary" :href="`/orders/${purchase.Id}/bill/${participant.Id}`"
           v-if="showParticipantInvoice && purchase.HasInvoices">
          <span class="d-md-none" style="padding:0 3px;"><fa icon="dollar-sign"/></span>
          <span class="d-none d-md-inline">счёт</span>
        </a>
      </div>
    </div>
  </li>
</template>

<script>
  import {mapGetters} from 'vuex'
  import Api from '@/api'
  import {
    ACTION_PURCHASE_PARTICIPANT_ASSEMBLY_CHANGE,
    ACTION_PURCHASE_PARTICIPANT_SHIPPING_CHANGE,
    logFeatureUse
  } from '@/util/ga'

  export default {
    name: 'ParticipantItem',
    props: ['purchase', 'cartId', 'participant', 'idx'],
    computed: {
      ...mapGetters({
        showParticipantInvoice: 'participantList/showParticipantInvoice',
        showAssemblyShipping: 'assembly/isAssemblyMode',
      })
    },
    methods: {
      handleIsAssembled() {
        // TODO: Fix this mess -- we should not mutate the props.
        this.participant.IsAssembled = !this.participant.IsAssembled
        Api.patch(`/Purchases/${this.purchase.Id}/Cart/${this.participant.Id}`, {
          IsAssembled: this.participant.IsAssembled
        }).then(() => {
          logFeatureUse(ACTION_PURCHASE_PARTICIPANT_ASSEMBLY_CHANGE, 'Via participant sidebar')
        }).catch(e => {
          alert(`Ошибка. ${e.message}`)

          this.participant.IsAssembled = !this.participant.IsAssembled // Undo the action.
        })
      },

      handleIsShipped() {
        // TODO: Fix this mess -- we should not mutate the props.
        this.participant.IsShipped = !this.participant.IsShipped
        Api.patch(`/Purchases/${this.purchase.Id}/Cart/${this.participant.Id}`, {
          IsShipped: this.participant.IsShipped
        }).then(() => {
          logFeatureUse(ACTION_PURCHASE_PARTICIPANT_SHIPPING_CHANGE, 'Via participant sidebar')
        }).catch(e => {
          alert(`Ошибка. ${e.message}`)

          this.participant.IsShipped = !this.participant.IsShipped // Undo the action.
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "src/styles/variables";

  .userlist {
    &__item-container {
      margin-top: -1px; // Change the 2px border to 1px apparent border.
    }

    & > li:first-child .input-group-append > .btn:last-child {
      border-top-right-radius: $border-radius-sm !important;
    }

    & > li:last-child .input-group-append > .btn:last-child {
      border-bottom-right-radius: $border-radius-sm !important;
    }

    & > li .input-group-append > .btn:last-of-type {
      line-height: 2 !important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    &__idx {
      width: 1.8125rem;
      text-align: right;
      padding-right: .5rem;
      user-select: none;
    }

    &__username {
      padding-left: .25rem;
      flex-grow: 1;
      margin-right: 0; // Reset the -1px offset.
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;

      background: inherit;

      &-active {
        background: $gray-200;
      }
    }
  }
</style>
