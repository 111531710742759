<template>
  <Modal ref="modal" title="Бронирование товара" v-if="show" @closed="close">
    <template v-slot:body>
      <label for="reservationSelect">
        Выберите бронь:
      </label>
      <select id="reservationSelect" class="custom-select" v-model="item.ReservationId">
        <option :value="0">&ndash; Без брони &ndash;</option>
        <option :value="r.Id" v-for="r in reservations" :key="`reservation-${r.Id}`">
          {{r.Name}}
        </option>
      </select>
    </template>
    <template v-slot:footer>
      <div class="spinner-border mr-2" role="status" v-if="loading"></div>
      <div class="text-danger" v-if="error">{{error}}</div>
      <button type="button" class="btn btn-primary" @click="save">Обновить и закрыть</button>
      <button type="button" class="btn btn-secondary" data-dismiss="modal">Закрыть</button>
    </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/Modal'
  import Api from '@/api'
  import {mapActions} from 'vuex'
  import {ACTION_RESERVATION_CHANGE_ITEM, logFeatureUse} from '@/util/ga'

  export default {
    name: 'ItemReservationDialog',
    components: {Modal},
    props: ['purchase'],

    data() {
      return {
        show: false,

        loading: false,
        error: null,

        itemId: '',
        item: {},
        reservations: [],
      }
    },

    methods: {
      open(itemId) {
        this.itemId = itemId
        this.show = true
        this.init()
      },

      close() {
        this.show = false
      },

      init() {
        this.loading = true
        this.error = null

        let promises = [
          Api.get(`/Items/${this.itemId}`).then(r => {
            this.item = r.data
          }),
          Api.get(`/Purchases/${this.purchase.Id}/Reservations`).then(r => {
            this.reservations = r.data
          })
        ]

        Promise.all(promises).then(() => {
          this.loading = false
        }).catch(e => {
          this.error = e.message
          this.loading = false
        })
      },

      save() {
        this.loading = true
        this.error = null
        Api.post(`/SwitchItemReservation`, {ItemId: this.itemId, ReservationId: this.item.ReservationId}).then(() => {
          this.loading = false

          logFeatureUse(ACTION_RESERVATION_CHANGE_ITEM)

          this.refreshParticipants({pid: this.purchase.Id, articleNumber: this.item.ArticleNumber})
          this.$refs.modal.close()
          this.$emit('updated', this.itemId)
        }).catch(e => {
          this.error = e.message
          this.loading = false
        })
      },

      ...mapActions({
        refreshParticipants: 'itemParticipants/fetchPurchaseItem'
      })
    }
  }
</script>

<style lang="scss" scoped>

</style>
