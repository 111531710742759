<template>
  <div>
    <h1>Пункты выдачи</h1>
    <hr>

    <div class="row">
      <div class="col-12 col-md-4 mb-4">
        <NewPointEditor @created="fetchPoints"/>
      </div>
      <div class="col-12 col-md-4">
        <div class="spinner-border" role="status" v-if="loading"></div>
        <div class="alert alert-danger" v-else-if="error">{{error}} <a href="" @click.prevent="fetchPoints">Повторить</a></div>
        <template v-else>
          <PickupPointsList :items="points" @delete="id => { deletePoint(id) }"/>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import Api from '@/api'
  import NewPointEditor from '@/components/PickupPoints/NewPointEditor'
  import PickupPointsList from '@/components/PickupPoints/PickupPointsList'
  import {ACTION_PICKUP_DELETE, logFeatureUse} from '@/util/ga'

  export default {
    name: 'PickupPoints',
    components: {PickupPointsList, NewPointEditor},
    created() {
      this.fetchPoints()
    },
    data() {
      return {
        loading: false,
        error: null,

        points: [],
      }
    },
    methods: {
      fetchPoints() {
        this.loading = true
        this.error = null
        return Api.get('/PickupPoints').then(r => {
          this.loading = false
          this.points = r.data
        }).catch(e => {
          this.loading = false
          this.error = e.message
        })
      },

      deletePoint(id) {
        Api.delete(`/PickupPoints/${id}`).then(() => {
          logFeatureUse(ACTION_PICKUP_DELETE)

          return this.fetchPoints()
        }).catch(e => {
          alert(e.message)
        })
      }
    }
  }
</script>

<style scoped>

</style>
