<template>
  <div>
    <template v-if="items.length === 0">
      Создайте свой первый пункт выдачи при помощи соседней формы.
    </template>
    <template v-else>
      <ul class="list-group">
        <PointItem :key="item.Id" :item="item" @delete="$emit('delete', item.Id)" v-for="item in items"></PointItem>
      </ul>
    </template>
  </div>
</template>

<script>
  import PointItem from '@/components/PickupPoints/PointItem'
  export default {
    name: 'PickupPointsList',
    components: {PointItem},
    props: ['items'],
  }
</script>

<style scoped>

</style>
