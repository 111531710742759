<template>
  <div>
    <div class="row mb-2">
      <div class="col-6 col-md-4 mb-2 mb-md-0">
        <label for="price">Цена за 1 шт, ₽</label>
        <input class="form-control" id="price" ref="price" v-model="price">
      </div>
      <div class="col-6 col-md-4">
        <label for="delivery">Доставка за 1 шт, ₽</label>
        <input class="form-control" id="delivery" ref="delivery" v-model="delivery">
      </div>
      <div class="col col-md-4">
        <label for="balance">Количество на складе</label>
        <input type="number" min="0" class="form-control" id="balance" v-model.number="s.Balance">
      </div>

      <div class="offset-md-4 col-md-8 mt-2">
        <div class="custom-control custom-checkbox custom-control-inline">
          <input type="checkbox" class="custom-control-input" id="isPaidDelivery" v-model="s.IsPaidDelivery">
          <label class="custom-control-label" for="isPaidDelivery">Платная доставка</label>
        </div>
        <div class="custom-control custom-checkbox custom-control-inline">
          <input type="checkbox" class="custom-control-input" id="isRemoteStore" v-model="s.IsRemoteStore">
          <label class="custom-control-label" for="isRemoteStore">Удалённый склад</label>
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col">
        <label for="minimalVolume">Минимальное количество (&laquo;от&raquo;)</label>
        <input type="number" min="1" class="form-control" id="minimalVolume" v-model.number="s.MinimalVolume">
      </div>
      <div class="col">
        <label for="multiplier">Кратность (&laquo;выкуп по&raquo;)</label>
        <input type="number" min="1" class="form-control" id="multiplier" v-model.number="s.Multiplier">
      </div>
      <div class="col">
        <label for="discount">Скидка, %</label>
        <input type="number" min="0" max="100" class="form-control" id="discount" v-model.number="s.DiscountRate">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col">
        <label for="wholesaleCategory">
          Категория опта
          <span class="spinner-border" style="width:1rem;height:1rem;" v-if="catLoading"></span>
        </label>
        <select id="wholesaleCategory" class="custom-select" v-model="s.WholesaleCategory">
          <option :value="0">Без опта</option>
          <option :value="c.Id" :key="c.Id" v-for="c in categories">{{c.Name}}</option>
        </select>
      </div>
      <div class="col">
        <label for="wholesalePrice">Оптовая цена за 1 шт, ₽</label>
        <input class="form-control" id="wholesalePrice" ref="wholesalePrice" v-model="wholesalePrice">
      </div>
    </div>
    <div class="d-flex">
      <button type="button" class="btn btn-primary mr-2" :disabled="loading" @click="submit">
        Сохранить
      </button>
      <Submitter btn-class="btn-info" :loading="loading" :error="error" @submitted="getFresh">Взять из Сима-ленд</Submitter>
    </div>
  </div>
</template>

<script>
  import cloneDeep from 'lodash/cloneDeep'
  import Api from '@/api'
  import Submitter from '@/components/Submitter'
  import forOwn from 'lodash/forOwn'
  import each from 'lodash/each'
  import Vue from 'vue'

  export default {
    name: 'SnapshotItemEditor',
    components: {Submitter},
    props: ['purchase', 'snapshot', 'articleId', 'volume'],
    data() {
      return {
        s: {
          SnapshotId: '',
          Id: '',
          ArticleNumber: 0,
          Price: '0',
          Delivery: '0',
          Balance: 0,
          MinimalVolume: 1,
          Multiplier: 1,
          IsPaidDelivery: false,
          IsRemoteStore: false,
          WholesaleCategory: 0,
          WholesalePrice: '0',
          DiscountRate: 0,
        },

        catLoading: false,
        categories: [],

        loading: false,
        error: null,
      }
    },
    computed: {
      price: {
        get() {
          return this.s.Price.replaceAll('.', ',')
        },
        set(v) {
          let nv = v.replaceAll(',', '.').replaceAll(/[^0-9.]/g, '')

          let pos = this.$refs.price.selectionStart
          if(nv.length !== v.length) {
            pos -= 1
          }
          this.s.Price = nv
          this.$forceUpdate()
          this.$nextTick(() => {
            this.$refs.price.selectionEnd = pos
          })
        }
      },
      delivery: {
        get() {
          return this.s.Delivery.replaceAll('.', ',')
        },
        set(v) {
          let nv = v.replaceAll(',', '.').replaceAll(/[^0-9.]/g, '')

          let pos = this.$refs.delivery.selectionStart
          if(nv.length !== v.length) {
            pos -= 1
          }
          this.s.Delivery = nv
          this.$forceUpdate()
          this.$nextTick(() => {
            this.$refs.delivery.selectionEnd = pos
          })
        }
      },
      wholesalePrice: {
        get() {
          return this.s.WholesalePrice.replaceAll('.', ',')
        },
        set(v) {
          let nv = v.replaceAll(',', '.').replaceAll(/[^0-9.]/g, '')

          let pos = this.$refs.wholesalePrice.selectionStart
          if(nv.length !== v.length) {
            pos -= 1
          }
          this.s.WholesalePrice = nv
          this.$forceUpdate()
          this.$nextTick(() => {
            this.$refs.wholesalePrice.selectionEnd = pos
          })
        }
      },
    },
    mounted() {
      forOwn(this.snapshot, (v, k) => {
        Vue.set(this.s, k, cloneDeep(v))
      })
      this.s.ArticleNumber = this.articleId

      this.catLoading = true
      Api.get(`/WholesaleCategories?SourceId=${this.purchase.SourceId}`).then(r => {
        this.catLoading = false
        this.categories = r.data
      })

      if(this.snapshot.ArticleNumber === 0) {
        this.getFresh()
      }
    },
    watch: {
      snapshot(s) {
        forOwn(s, (v, k) => {
          Vue.set(this.s, k, cloneDeep(v))
        })
      }
    },

    methods: {
      submit() {
        if (this.s.Multiplier === 0) {
          this.error = '«Выкуп по» не должен быть меньше единицы.'
          return
        }

        this.loading = true
        this.error = null
        Api.put(`/Purchases/${this.purchase.Id}/Items/${this.articleId}/Snapshots`, {Snapshot: this.s}).then(() => {
          this.loading = false
          this.$emit('updated')
        }).catch(e => {
          this.loading = false
          this.error = e.message
        })
      },
      getFresh() {
        const sid = this.purchase.SettlementId

        this.loading = true
        this.error = null
        Api.get(`/Articles/${this.articleId}?settlementId=${sid}&volume=${this.volume}`).then(r => {
          this.loading = false

          const fields = ['Price', 'Balance', 'MinimalVolume', 'Multiplier', 'Delivery', 'IsPaidDelivery',
            'IsRemoteStore', 'WholesaleCategory', 'WholesalePrice', 'DiscountRate']
          each(fields, field => {
            Vue.set(this.s, field, r.data[field])
          })
        }).catch(e => {
          this.loading = false
          this.error = e.message
        })
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
