<template>
  <Modal ref="modal" title="Описание закупки" v-if="show" @closed="close">
    <template v-slot:body>
      <textarea rows="15" class="form-control description-editor" v-model="newDescription"/>
    </template>
    <template v-slot:footer>
      <div class="spinner-border mr-2" role="status" v-if="loading"></div>
      <div class="text-danger" v-if="error">{{error}}</div>
      <button type="button" class="btn btn-primary" @click="save">Сохранить и закрыть</button>
      <button type="button" class="btn btn-secondary" data-dismiss="modal">Закрыть</button>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal'
import Api from '@/api'
import {ACTION_PURCHASE_CHANGE_DESCRIPTION, logFeatureUse} from '@/util/ga'
export default {
  name: "DescriptionEditor",
  components: {Modal},
  props: ['id', 'description'],

  data() {
    return {
      show: false,

      loading: false,
      error: null,

      newDescription: '',
    }
  },

  methods: {
    open() {
      this.show = true
      this.newDescription = this.description
    },

    close() {
      this.show = false
    },

    save() {
      this.loading = true
      this.error = null
      Api.patch(`/Purchases/${this.id}`, {Description: this.newDescription}).then(() => {
        logFeatureUse(ACTION_PURCHASE_CHANGE_DESCRIPTION)

        this.$emit('updated')
        this.close()
      }).catch(e => {
        this.error = e.message
      }).finally(() => {
        this.loading = false
      })
    },
  }
}
</script>

<style lang="scss" scoped>
  @import "src/styles/variables";

  .description-editor {
    font-family: $font-family-monospace;
    font-size: .85rem;
  }
</style>
