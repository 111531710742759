<template>
  <div>
    <form @submit.prevent="submit">
      <div class="mb-2">
        <label for="name">Название пункта</label>
        <input class="form-control" id="name" required v-model.trim="name">
      </div>
      <div class="mb-2">
        <label for="address">Адрес</label>
        <input class="form-control" id="address" v-model.trim="address">
      </div>
      <div class="mt-4">
        <Submitter>Добавить</Submitter>
      </div>
    </form>
  </div>
</template>

<script>
  import Api from '@/api'
  import Submitter from '@/components/Submitter'
  import {ACTION_PICKUP_CREATE, logFeatureUse} from '@/util/ga'

  export default {
    name: 'NewPointEditor',
    components: {Submitter},
    data() {
      return {
        name: '',
        address: '',

        loading: false,
        error: null,
      }
    },
    methods: {
      submit() {
        this.loading = true
        this.error = null
        Api.post('/PickupPoints', {DisplayName: this.name, Address: this.address}).then(r => {
          this.loading = false
          this.name = ''
          this.address = ''

          const newId = r.data.Id
          this.$emit('created', newId)

          logFeatureUse(ACTION_PICKUP_CREATE)
        }).catch(e => {
          this.loading = false
          this.error = e.message
        })
      }
    }
  }
</script>

<style scoped>

</style>
