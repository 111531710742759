<template>
  <ul class="wholesale-categories pl-0">
    <li v-for="c in wc" :key="c.Id">
      <WholesaleCategoriesItem :c="c" :purchase-wc="purchaseWc"
                               @change-wc="$emit('change-wc', $event)"
                               v-model="selectedCategories"/>
    </li>
  </ul>
</template>

<script>
  import WholesaleCategoriesItem from '@/components/Settings/WholesaleCategoriesItem'

  export default {
    name: 'WholesaleCategories',
    components: {WholesaleCategoriesItem},
    model: {
      prop: 'selectedWc',
      event: 'change',
    },

    props: {
      wc: Array,
      purchaseWc: Array,
      selectedWc: Array,
    },

    computed: {
      selectedCategories: {
        get() {
          return this.selectedWc
        },
        set(v) {
          this.$emit('change', v)
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import "src/styles/variables";
  @import "~bootstrap/scss/mixins";

  .wholesale-categories {
    list-style: none;

    padding-left: 0;
    margin-bottom: 0;

    display: grid;
    grid-gap: map-get($spacers, 3) map-get($spacers, 3);

    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
</style>
