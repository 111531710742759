<template>
  <div class="d-flex flex-nowrap justify-content-between p-2" style="border: 1px solid #eceeef;">
    <div style="padding-top: 4px;margin-bottom:2px">
      <div class="custom-control custom-checkbox">
        <input :id="`wcFlag${cid}`" type="checkbox" class="custom-control-input" :value="c.Id" v-model="selectedCategories">
        <label class="custom-control-label" :for="`wcFlag${cid}`">
          {{ c.Name }}

          <a :href="`https://www.sima-land.ru/krupniy-opt/${c.Slug}/`" target="_blank"><small>?</small></a>

          <template v-if="isCustomRateSet">
            <span class="custom-rate text-nowrap" title="Альтернативный курс для оптовой категории.">
              1 RUB = {{customExchangeRate|money('BYN', true)}}

              <a href="" class="badge badge-sm badge-danger text-white"
                 @click.prevent.stop="disableCustomRate">
                <fa icon="times"/>
              </a>
            </span>
          </template>
        </label>
      </div>
    </div>

    <div ref="dropdownContainer" v-if="isEnabled">
      <div class="dropdown dropleft">
        <button ref="dropdown" class=" btn btn-sm btn-outline-secondary" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
          <fa icon="ellipsis-v"/>
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <button class="dropdown-item" @click.stop="configuringExchangeRate = true" v-if="!(configuringExchangeRate || isCustomRate)">
            Установить альтернативный курс
          </button>
          <div v-else>
            <h6 class="dropdown-header font-weight-bold">
              Альтернативный курс

              <a href="" class="font-weight-normal text-danger float-right"
                 @click.prevent.stop="disableCustomRate(); hideDropdown()">
                Снять
              </a>
            </h6>
            <form class="px-4 py-2">
              <div class="mb-2">
                <ExchangeRateControl currency-code="TODO" btn-label="Установить" :disabled="!isCustomRate"
                                     @input="hideDropdown" v-model="customExchangeRate"/>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ExchangeRateControl from '@/components/ExchangeRateControl'
  import 'bootstrap/js/src/dropdown'
  import $ from 'jquery'
  import uniqueId from 'lodash/uniqueId'
  import includes from 'lodash/includes'
  import find from 'lodash/find'

  export default {
    name: 'WholesaleCategoriesItem',
    components: {ExchangeRateControl},
    model: {
      prop: 'selectedWc',
      event: 'change',
    },

    props: {
      c: Object,
      selectedWc: Array,
      purchaseWc: Array,
    },

    watch: {
      isCustomRate() {
        this.$nextTick(() => {
          $(this.$refs['dropdown']).dropdown('update')
        })
      },
    },

    mounted() {
      $(this.$refs['dropdownContainer']).on('hidden.bs.dropdown', () => {
        this.configuringExchangeRate = false
      })
    },

    data() {
      return {
        cid: uniqueId(),

        configuringExchangeRate: false,
      }
    },

    computed: {
      selectedPurchaseWc() {
        return find(this.purchaseWc, {Id: this.c.Id}) || {
          Id: this.c.Id,
          IsCustomRate: false,
          CustomExchangeRate: '',
        }
      },

      selectedCategories: {
        get() {
          return this.selectedWc
        },
        set(v) {
          this.$emit('change', v)
        }
      },

      wcSettings: {
        get() {
          return this.selectedPurchaseWc
        },
        set(v) {
          this.$emit('change-wc', v)
        }
      },

      isCustomRate: {
        get() {
          return this.wcSettings.IsCustomRate || false
        },
        set(v) {
          this.wcSettings = {...this.wcSettings, IsCustomRate: v}
        }
      },

      customExchangeRate: {
        get() {
          return this.wcSettings.CustomExchangeRate || ''
        },
        set(v) {
          this.wcSettings = {...this.wcSettings, IsCustomRate: true, CustomExchangeRate: v}
        }
      },

      isEnabled() {
        return includes(this.selectedCategories, this.c.Id)
      },

      isCustomRateSet() {
        return this.isCustomRate && this.customExchangeRate.trim().length > 0
      }
    },

    methods: {
      hideDropdown() {
        this.configuringExchangeRate = false
        $(this.$refs['dropdown']).dropdown('hide')
      },

      enableCustomRate() {
        this.isCustomRate = true
      },

      disableCustomRate() {
        this.isCustomRate = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "src/styles/variables";

  .custom-rate {
    color: $gray-600;
    font-family: $font-family-monospace;
    font-size: $font-size-sm;
  }
</style>
