<template>
  <div>
    <h1>Редактирование профиля</h1>
    <ProfileEditor :user-id="id"/>
  </div>
</template>

<script>
  import userMx from '@/util/mixin-user'
  import ProfileEditor from '@/components/Profile/ProfileEditor'

  export default {
    name: 'ChangeProfile',
    components: {ProfileEditor},
    mixins: [userMx],
    props: ['id'],
  }
</script>
