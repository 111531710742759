<template>
  <div>
    <h1>
      <router-link class="back-button" :to="{ name: 'purchase', params: { id: id }}">&larr;</router-link>
      {{ ' ' }}
      Новая бронь <small class="text-muted font-weight-light">{{ purchase.Name || 'Загрузка...' }}</small>
    </h1>

    <Reservation :purchase="purchase" @saved="rsv => onSaved(rsv)"/>
  </div>
</template>

<script>
import Api from '../api'
import userMixin from '../util/mixin-user'
import Reservation from '@/components/Reservation'
import {ACTION_RESERVATION_CREATE, getReservationFeatureUseLabel, logFeatureUse} from '@/util/ga'

export default {
  name: "NewReservation",
  components: {Reservation},
  mixins: [userMixin],
  props: ['id'],
  data() {
    return {
      loading: false,
      error: null,

      purchase: {},
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    fetch() {
      return Api.get(`/Purchases/${this.id}/Metadata`).then(r => {
        this.purchase = r.data
      })
    },

    onSaved(rsv) {
      logFeatureUse(ACTION_RESERVATION_CREATE, getReservationFeatureUseLabel(rsv))
      this.$router.push({name: 'purchase', params: {id: this.id}})
    }
  }
}
</script>

<style scoped>

</style>
