<template>
  <tr>
    <td>
      <div>
        <router-link :to="{ name: 'manage-reservation', params: { id: item.PurchaseId, rid: item.Id }}" tag="a" class="h5">
          {{ item.Name }}
        </router-link>
        <span class="badge badge-primary font-weight-normal ml-1" v-if="false">{{ statusLabel }}</span>
        <span class="badge badge-secondary font-weight-normal ml-1">{{ pricingLabel }}</span>
      </div>
      <div class="text-muted">
        <small>
          Создана {{ item.CreatedAt|moment }} ({{ item.OwnerIdName }}).
          <template v-if="isSimaOrderAttached">
            Заказ <a :href="`https://www.sima-land.ru/order/${item.SimaOrderId}/`"
                     target="_blank">&#8470;{{ item.SimaOrderId }}</a>.
          </template>
        </small>
      </div>
    </td>
    <td>
      <button type="button" class="btn btn-success btn-sm mr-1" title="Добавить корзину в бронь" @click="$emit('append')" v-if="isCart">
        <fa icon="cart-plus" />
      </button>
      <router-link :to="{ name: 'manage-reservation', params: { id: item.PurchaseId, rid: item.Id }}" tag="a"
                   class="btn btn-sm btn-primary mr-1" title="Управление бронью">
        <fa icon="cogs" />
      </router-link>
      <button type="button" class="btn btn-primary btn-sm mr-1" title="Расхождения в объёмах заказа"
              @click="$emit('discrepancies')" v-if="item.SimaOrderId > 0">
        <fa icon="balance-scale-left" />
      </button>
      <button type="button" class="btn btn-warning btn-sm mr-1" title="Отправить на sima-land.ru"
              @click="$emit('export')">
        <fa icon="dolly-flatbed" />
      </button>
      <button type="button" class="btn btn-sm btn-danger mr-1" title="Удалить бронь" @click="$emit('deleted')">
        <fa icon="trash-alt" />
      </button>
    </td>
  </tr>
</template>

<script>
const STATUS_LABEL_MAP = {
  0: 'Без привязки',
  1: 'Заказ привязан',
  2: 'Проблема с заказом',
}

const PRICING_LABEL_MAP = {
  0: 'Цены из таблицы',
  1: 'Цены из заказа',
  2: 'Цены из заморозки',
}

export default {
  name: "ReservationsListItem",
  props: ['item', 'cartId'],
  computed: {
    statusLabel() {
      return STATUS_LABEL_MAP[this.item.Status]
    },

    pricingLabel() {
      return PRICING_LABEL_MAP[this.item.PricingMethod]
    },

    isSimaOrderAttached() {
      return this.item.SimaOrderId > 0
    },

    purchaseId() {
      return this.item.PurchaseId
    },

    isCart() {
      return !!this.cartId
    }
  }
}
</script>

<style scoped>

</style>
