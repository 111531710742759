<template>
  <div>
    <form @submit.prevent="submit" autocomplete="off">
      <div class="row">
        <div class="col-md-6">
          <div class="form-row">
            <div class="form-group col">
              <label for="reservationName">Название брони</label>
              <input type="text" class="form-control" id="reservationName" ref="reservationName" required
                     v-model.trim="rsv.Name">
            </div>
            <div class="col"></div>
          </div>
          <!-- TODO: Implement other pricing modes. -->
          <div class="form-row">
            <div class="form-group col">
              Режим цен

              <fieldset>
                <div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" name="priceMode" id="prices-current" class="custom-control-input" checked
                           :value="constant.RESERVATION_PRICING_DEFAULT"
                           v-model.number="rsv.PricingMethod">
                    <label class="custom-control-label" for="prices-current">Текущие цены из таблицы</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline" v-if="false">
                    <input type="radio" name="priceMode" id="prices-order" class="custom-control-input"
                           :value="constant.RESERVATION_PRICING_ORDER"
                           @click="focusSimaOrderId"
                           v-model.number="rsv.PricingMethod">
                    <label class="custom-control-label" for="prices-order">По заказу</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" name="priceMode" id="prices-snapshot" class="custom-control-input"
                           :value="constant.RESERVATION_PRICING_SNAPSHOT"
                           v-model.number="rsv.PricingMethod">
                    <label class="custom-control-label" for="prices-snapshot">Заморозка брони</label>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="col"></div>
          </div>

          <div class="form-row mb-3" v-if="rsv.PricingMethod === constant.RESERVATION_PRICING_SNAPSHOT">
            <div class="form-group col">
              <div class="input-group">
                <select id="snapshotSelector" class="custom-select" v-model="rsv.SnapshotId">
                  <option value="00000000-0000-0000-0000-000000000000">Текущие с Сима-ленд</option>
                  <option :value="s.Id" v-for="s in snapshots" :key="s.Id">Сделанные {{s.CreatedAt | moment}}
                    ({{s.CreatedByName}})
                  </option>
                </select>
                <div class="input-group-append">
                  <button type="button" class="btn btn-info" @click="handleDoSnapshot">Заморозить цены</button>
                </div>
              </div>
            </div>
            <div class="col"></div>
          </div>
          <div class="form-row">
            <div class="form-group col">
              <label for="simaOrderId">Номер заказа / заявки</label>
              <input type="text" class="form-control" id="simaOrderId" ref="simaOrderId" pattern="\d+"
                     title="Номер заказа или заявки — это число."
                     :required="rsv.PricingMethod === constant.RESERVATION_PRICING_ORDER"
                     v-model.number="simaOrderId">
            </div>
            <div class="col"></div>
          </div>
          <div class="form-row" v-if="!exists">
            <div class="form-group col">
              Добавить товары

              <div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" name="itemChoice" id="items-all" class="custom-control-input"
                         :value="true"
                         v-model="rsv.Items.AllItems">
                  <label class="custom-control-label" for="items-all">Все незабронированные</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" name="itemChoice" id="items-selected" class="custom-control-input"
                         :value="false"
                         v-model="rsv.Items.AllItems">
                  <label class="custom-control-label" for="items-selected">Вручную</label>
                </div>
              </div>
            </div>
            <div class="col"></div>
          </div>
          <div class="form-row">
            <div class="form-group col">
              <div class="d-flex align-items-center">
                <button type="submit" class="btn btn-primary mr-2">
                  <template v-if="exists">
                    Сохранить и вернуться
                  </template>
                  <template v-else>
                    Создать и вернуться
                  </template>
                </button>
                <template v-if="exists">
                  <button type="button" class="btn btn-danger mr-2" @click="onDelete">Удалить</button>
                </template>
                <template v-if="loading">
                  <div class="spinner-border mr-2" style="width:1rem;height:1rem;"></div>
                </template>
                <template v-if="error">
                  <span class="result-msg text-danger">Ошибка при изменении. {{error}}</span>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import Api from '@/api'

const constants = {
  RESERVATION_PRICING_DEFAULT: 0,
  RESERVATION_PRICING_ORDER: 1,
  RESERVATION_PRICING_SNAPSHOT: 2,
}

export default {
  name: "Reservation",

  props: ['reservation', 'purchase'],

  created() {
    if(this.reservation) {
      this.rsv = cloneDeep(this.reservation)
    }
  },

  mounted() {
    this.$refs.reservationName.focus()
  },

  computed: {
    exists() {
      return !!this.reservation
    },

    /**
     * Virtualize the SimaOrderId field to replace 0 value with an empty string.
     */
    simaOrderId: {
      get() {
        if(this.rsv.SimaOrderId === 0) {
          return ''
        }

        return this.rsv.SimaOrderId
      },

      set(v) {
        if(v === '') {
          this.rsv.SimaOrderId = 0
        }

        this.rsv.SimaOrderId = +v
      }
    }
  },

  data() {
    return {
      loading: false,
      error: null,

      constant: constants,

      rsv: {
        Id: 0,
        Name: '',
        PricingMethod: constants.RESERVATION_PRICING_DEFAULT,
        SimaOrderId: 0,
        SnapshotId: '00000000-0000-0000-0000-000000000000',
        Items: {
          AllItems: true,
          Items: [],
        }
      },

      snapshots: {},
    }
  },

  methods: {
    fetchSnapshots() {
      /*this.loaded.snapshots = false
      this.loading.snapshots = true
      this.error.snapshots = null*/

      return Api.get(`/Reservations/${this.rsv.Id}/Snapshots`).then(r => {
        this.snapshots = r.data.Snapshots
        /*this.loading.snapshots = false
        this.loaded.snapshots = true*/
      }).catch(e => {
        /*this.loading.snapshots = false
        this.error.snapshots = e.message*/
      })
    },

    focusSimaOrderId() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.simaOrderId.focus()
          this.$refs.simaOrderId.select()
        }, 0)
      })
    },

    submit() {
      this.loading = true
      this.error = null

      let p;
      if(this.exists) {
        p = Api.patch(`/Reservations/${this.reservation.Id}`, this.rsv)
      } else {
        p = Api.post(`/Purchases/${this.purchase.Id}/Reservations`, this.rsv)
      }

      p.then(() => {
        this.loading = false
        this.$emit('saved', this.rsv)
      }).catch(e => {
        this.loading = false
        this.error = e.message
      })
    },

    onDelete() {
      if(!confirm('Вы уверены, что хотите удалить эту бронь?')) {
        return;
      }

      this.loading = true
      this.error = null
      Api.delete(`/Reservations/${this.reservation.Id}`).then(() => {
        this.loading = false
        this.$emit('deleted') // TODO: Differentiate from `saved`.
      }).catch(e => {
        this.loading = false
        this.error = e.message
      })
    },

    handleDoSnapshot() {
      /*this.loading.snapshots = true
      this.loaded.snapshots = false
      this.error.snapshots = null*/

      Api.post(`/Reservations/${this.reservation.Id}/Snapshots`).then(r => {
        /*this.loading.snapshots = false
        this.loaded.snapshots = true*/
        this.fetchSnapshots().then(() => {
          this.rsv.SnapshotId = r.data.SnapshotId
        })
      }).catch(e => {
        /*this.loading.snapshots = false
        this.error.snapshots = e.message*/
      })
    },
  },

  watch: {
    reservation(n) {
      if(n) {
        this.rsv = cloneDeep(n)

        if(this.rsv.Id > 0) {
          this.fetchSnapshots()
        }
      }
    },
    'rsv.SnapshotId'(v) {
      if(v === '00000000-0000-0000-0000-000000000000') {
        this.rsv.PricingMethod = constants.RESERVATION_PRICING_DEFAULT
      }
    }
  }
}
</script>

<style scoped>

</style>
