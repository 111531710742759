<template>
  <div>
    <h1>
      Создание новой совместной закупки
    </h1>

    <form @submit.prevent="submit" autocomplete="off">
      <div class="row mb-4">
        <div class="col-12 col-sm-8 col-md-6 col-lg-4">
          <div class="mb-2">
            <label for="name">Название</label>
            <input class="form-control" id="name" placeholder="Название новой совместной закупки" required autofocus
                   ref="nameInput" v-model.trim="name">
          </div>
          <div class="mb-2">
            <label for="description">Описание <em class="font-weight-light">(необязательно)</em></label>
            <textarea class="form-control" id="description" name="description"
                      placeholder="Введите описание совместной закупки (например, правила)." rows="5"
                      v-model.trim="description"></textarea>
            <small class="form-text text-muted">В описании поддерживается синтаксис
              <a href="https://paulradzkov.com/2014/markdown_cheatsheet/" target="_blank">Markdown</a> для оформления ссылок,
              списков и других элементов.
            </small>
          </div>
          <div class="mb-2">
            <label>Населённый пункт для расчёта доставки</label>
            <SimaSettlementSelector :id="settlementId" @updated="handleSettlement"/>
            <small class="form-text text-muted">
              Населённый пункт по умолчанию можно выбрать в <router-link :to="{name: 'my-profile'}">настройках профиля</router-link>.
            </small>
          </div>

          <div class="d-none d-md-block">
            <div class="mb-2 mt-4">
              <button type="submit" class="btn btn-primary mr-2">Создать</button>
              <router-link class="btn btn-link" :to="{name: 'purchases'}">Вернуться к списку закупок</router-link>
            </div>
            <div>
              <div class="spinner-border mr-2" role="status" v-if="loading"></div>
              <div class="text-danger" v-else-if="error">{{error}}</div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-4 col-md-4 col-lg-3" v-if="pickupPoints.length > 0">
          Пункты выдачи

          <PickupPoint :item="item" :key="item.Id" v-for="item in pickupPoints" v-model="selectedPoints" />
        </div>
      </div>

      <div class="row d-md-none">
        <div class="col">
          <div class="mb-2">
            <button type="submit" class="btn btn-primary mr-2">Создать</button>
            <router-link class="btn btn-link" :to="{name: 'purchases'}">Вернуться к списку закупок</router-link>
          </div>
          <div>
            <div class="spinner-border mr-2" role="status" v-if="loading"></div>
            <div class="text-danger" v-else-if="error">{{error}}</div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  import store from '@/store'
  import Api from '@/api'
  import SimaSettlementSelector from '@/components/SimaSettlementSelector/SimaSettlementSelector'
  import PickupPoint from '@/components/NewPurchase/PickupPoint'
  import map from 'lodash/map'
  import filter from 'lodash/filter'
  import {ACTION_PURCHASE_CREATE, logFeatureUse} from '@/util/ga'

  export default {
    name: 'NewPurchase',
    components: {PickupPoint, SimaSettlementSelector},
    beforeRouteEnter(to, from, next) {
      if(!store.getters['auth/canCreatePurchases']) {
        next({name: 'not-found'})
        return
      }
      next()
    },

    created() {
      this.fetchPickupPoints()
    },

    mounted() {
      this.$refs.nameInput.focus()
    },

    data() {
      return {
        name: '',
        description: '',
        settlementId: this.$store.state.auth.user.SimaSettlementId,

        pickupPoints: [],
        selectedPoints: [],

        loading: false,
        error: null,
      }
    },

    methods: {
      submit() {
        this.loading = true
        this.error = null
        Api.post('/Purchases', {
          Name: this.name,
          Description: this.description,
          SimaSettlementId: this.settlementId,
          PickupPoints: this.selectedPoints,
        }).then(r => {
          logFeatureUse(ACTION_PURCHASE_CREATE)

          const pid = r.data.PurchaseId
          this.$router.push({name: 'purchase', params: {id: pid}})
        }).catch(e => {
          this.loading = false
          this.error = e.message
        })
      },

      handleSettlement(newId) {
        if(!newId) {
          return
        }

        this.settlementId = newId
      },

      fetchPickupPoints() {
        Api.get('/PickupPoints?forNewPurchase=true').then(r => {
          this.pickupPoints = r.data

          this.selectedPoints = map(filter(this.pickupPoints, {IsActive: true}), p => p.Id)
        })
      }
    }
  }
</script>

<style scoped>

</style>
