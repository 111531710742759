<template>
  <li @click="$emit('chosen', option)">
    <span class="wrapper" v-html="formatted"/>
  </li>
</template>

<script>
  function highlight(str, q) {
    if(q === '') {
      return str
    }

    const pos = str.toLowerCase().indexOf(q.toLowerCase())
    if(pos === -1) {
      return str
    }

    const qLen = (pos + q.length === str.length)? str.length : q.length

    return `${str.slice(0, pos)}<strong>${str.slice(pos, qLen)}</strong>${str.slice(pos + q.length)}`
  }

  export default {
    name: 'OptionRow',

    props: {
      query: {
        type: String,
      },
      option: {
        type: Object,
      }
    },

    computed: {
      formatted() {
        const name = highlight(this.option.Name, this.query)

        if(this.option.Region === this.option.Name) {
          return name
        }

        return `${name} (${highlight(this.option.Region, this.query)})`
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "src/styles/variables";

  li {
    cursor: pointer;
    padding: ($spacer / 4) ($spacer / 2);


    &:hover {
      background: $gray-200;
    }
  }
</style>
