<template>
  <tr>
    <td>
      <router-link :to="{ name: 'cart', params: { id: p.Id, uid: pp.Id } }">{{pp.DisplayName}}</router-link>
      <template v-if="pp.Id === userId">
        {{' '}}<em>(вы)</em>
      </template>
    </td>
    <td>
      <template v-if="pp.Id === p.OwnerId">
        {{' '}}<em>Организатор и владелец закупки.</em>
      </template>
      <template v-else>
        <div class="d-flex flex-wrap flex-md-nowrap">
          <div>
            <button class="btn btn-sm btn-danger mr-1" @click="exclude">Исключить</button>
            <button class="btn btn-sm mr-1" @click="toggleAdmin"
                    :class="{ 'btn-secondary': pp.IsPurchaseAdmin, 'btn-primary': !pp.IsPurchaseAdmin }">
              <template v-if="pp.IsPurchaseAdmin">
                Сделать участником
              </template>
              <template v-else>
                Сделать администратором
              </template>
            </button>
            <button class="btn btn-sm btn-success" @click="makeOwner" v-if="isGlobalAdmin">Передать права</button>
          </div>
          <div class="ml-2 border-left pl-2 pr-2" v-if="loading || error">
            <div :class="{ 'visible': loading, 'invisible': !loading }" class="spinner-border mr-2"
                 style="width:1rem;height:1rem;border-width:2px;"></div>
            <template v-if="error">
              <small class="result-msg text-danger">{{error}}</small>
            </template>
          </div>
        </div>
      </template>
    </td>
  </tr>
</template>

<script>
  import Api from '@/api'
  import userMixin from '@/util/mixin-user'
  import {mapGetters} from 'vuex'

  export default {
    name: 'ParticipantRow',

    props: ['purchase', 'purchaseParticipant',],

    mixins: [userMixin,],

    data() {
      return {
        loading: false,
        error: null
      }
    },

    computed: {
      p() {
        return this.purchase
      },

      pp() {
        return this.purchaseParticipant
      },

      ...mapGetters({
        isGlobalAdmin: 'auth/isAdmin',
      })
    },

    methods: {
      exclude() {
        if(!confirm(`Участник ${this.pp.DisplayName} будет исключен, а все их товары будут удалены. Продолжить?`)) {
          return;
        }

        this.loading = true
        this.error = null
        Api.delete(`/Purchases/${this.p.Id}/Participants/${this.pp.Id}`).then(() => {
          this.loading = false
          this.$emit('updated')
        }).catch(e => {
          this.loading = false
          this.error = e.message
        })
      },

      toggleAdmin() {
        this.loading = true
        this.error = null
        Api.patch(`/Purchases/${this.p.Id}/Participants/${this.pp.Id}`, {
          IsPurchaseAdmin: !this.pp.IsPurchaseAdmin,
        }).then(() => {
          this.loading = false
          this.$emit('updated')
        }).catch(e => {
          this.loading = false
          this.error = e.message
        })
      },

      makeOwner() {
        this.loading = true
        this.error = null
        Api.patch(`/Purchases/${this.p.Id}`, {OwnerId: this.pp.Id}).then(() => {
          this.loading = false
          this.$emit('updated')
        }).catch(e => {
          this.loading = false
          this.error = e.message
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "src/styles/variables";
  @import "~bootstrap/scss/mixins";

  .btn {
    @include media-breakpoint-only(xs) {
      margin-bottom: $btn-padding-y;
    }
  }
</style>
