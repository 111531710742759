<template>
  <div>
    <h1>
      <router-link class="back-button" :to="{ name: 'purchase', params: { id: id }}">&larr;</router-link>
      {{ ' ' }}
      {{ reservation.Name || '' }} <small class="text-muted font-weight-light">{{ purchase.Name || 'Загрузка...' }}</small>
    </h1>

    <Reservation :purchase="purchase" :reservation="reservation" @saved="rsv => onSaved(rsv)" @deleted="onDeleted"/>
  </div>
</template>

<script>
import Api from '@/api'
import userMixin from '@/util/mixin-user'
import Reservation from '@/components/Reservation'
import {
  ACTION_RESERVATION_CHANGE, ACTION_RESERVATION_DELETE,
  getReservationFeatureUseLabel,
  logFeatureUse
} from '@/util/ga'

export default {
  name: 'ManageReservation',
  components: {Reservation},
  mixins: [userMixin],
  props: ['id', 'rid'],
  data() {
    return {
      loading: false,
      error: null,

      purchase: {},
      reservation: {
        Id: 0,
        Name: '',
        PricingMethod: 0,
        SimaOrderId: 0,
        Items: {
          AllItems: true,
          Items: [],
        }
      },
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    fetch() {
      Api.get(`/Purchases/${this.id}/Metadata`).then(r => {
        this.purchase = r.data
      })

      Api.get(`/Reservations/${this.rid}`).then(r => {
        this.reservation = r.data
      })
    },

    onSaved(rsv) {
      logFeatureUse(ACTION_RESERVATION_CHANGE, getReservationFeatureUseLabel(rsv))
      this.$router.push({name: 'purchase', params: {id: this.id}})
    },

    onDeleted() {
      logFeatureUse(ACTION_RESERVATION_DELETE)
      this.$router.push({name: 'purchase', params: {id: this.id}})
    }
  }
}
</script>

<style scoped>

</style>
