<template>
  <Modal title="Приглашение" ref="modal" v-if="show" @closed="close">
    <template v-slot:body>
      <div class="form-group mb-1">
        <p>
          По этой ссылке другие участники могут присоединиться к закупке.
          Скопируйте <a :href="inviteUrl">ссылку</a> и пошлите её тем, кого хотите пригласить.
        </p>
        <div class="input-group">
          <input class="form-control" id="urlOrArticleNo" :value="inviteUrl" readonly @click="copy">
          <div class="input-group-append">
            <button type="button" class="btn btn-secondary" @click="copy">
              <fa icon="copy"/>
            </button>
          </div>
        </div>
        <div class="form-text text-muted" :class="{'invisible': !copied}">
          <small>Ссылка скопирована в буфер обмена.</small>
        </div>
      </div>
      <hr>
      <div class="form-group mb-0">
        <h5>Добавить по e-mail</h5>
        <p>
          Если вы знаете e-mail зарегистрированного пользователя, вы можете добавить его в закупку самостоятельно.
        </p>
        <form @submit.prevent="invite">
          <div class="input-group">
            <input type="email" class="form-control" placeholder="E-mail пользователя" required v-model="email"
                   ref="inviteEmail">
            <div class="input-group-append">
              <button type="submit" class="btn btn-outline-secondary">Пригласить</button>
            </div>
          </div>
        </form>
        <div class="form-text">
          <template v-if="loading">
            <div class="spinner-border" style="width:1rem;height:1rem;"></div>
          </template>
          <template v-else-if="error">
            <small class="text-danger">{{error}}</small>
          </template>
          <template v-else-if="loaded">
            <small>Пользователь успешно добавлен в закупку.</small>
          </template>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
  import Api from "../api"
  import Modal from "./Modal";
  import * as clipboard from "clipboard-polyfill"

  export default {
    name: "InviteDialog",
    components: {Modal},
    props: ['id'],
    data() {
      return {
        show: false,
        copied: false,

        email: '',
        loading: false,
        loaded: false,
        error: null,
      }
    },

    computed: {
      inviteUrl() {
        const base = this.$store.state.auth.user.UrlBase

        return `${base}/invite/${this.id}`
      }
    },

    methods: {
      open() {
        this.show = true
      },

      close() {
        this.show = false
        this.copied = false
        this.loaded = false
        this.error = null
        this.email = ''
      },

      copy() {
        clipboard.writeText(this.inviteUrl).then(() => {
          this.copied = true
        })
      },

      invite() {
        this.loading = true
        this.loaded = false
        this.error = null
        Api.put(`/Purchases/${this.id}/Participants`, {
          email: this.email,
        }).then(() => {
          this.loading = false
          this.loaded = true

          this.$emit('invited')

          this.$refs.inviteEmail.select()
        }).catch(e => {
          this.loading = false
          this.error = e.message
          this.$refs.inviteEmail.select()
        })
      }
    }
  }
</script>

<style scoped>

</style>
