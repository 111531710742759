<template>
  <Modal title="Бронирование товаров" size="lg" ref="modal" no-padding v-if="show" @closed="close">
    <template v-slot:body>
      <table class="table mb-0" v-if="items.length > 0">
        <thead>
          <tr>
            <th>Название</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <ReservationsListItem @deleted="deleteItem(item.Id)" @export="exportToSima(item.Id)"
                                @discrepancies="$emit('discrepancies', item.Id)"
                                @append="appendCartToReservation(item.Id)"
                                :cart-id="cartId"
                                :item="item" :key="item.Id"
                                v-for="item in items"/>
        </tbody>
      </table>
      <p class="p-3 m-0" v-else-if="!loading && !error">
        Бронирование не осуществлялось.
      </p>
    </template>
    <template v-slot:footer>
      <div class="spinner-border mr-2" role="status" v-if="loading"></div>

      <div class="text-danger" v-if="error">{{ error }}</div>
      <div class="text-success" v-if="success">{{ success }}</div>

      <router-link tag="button" type="button" class="btn btn-primary" :to="{ name: 'new-reservation', params: { id } }">
        Создать бронь
      </router-link>
      <button type="button" class="btn btn-secondary" data-dismiss="modal">Закрыть</button>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal'
import Api from '@/api'
import ReservationsListItem from '@/components/ReservationsListItem'
import find from 'lodash/find'
import {ACTION_FILL_SIMA_CART, ACTION_RESERVATION_DELETE, logFeatureUse} from '@/util/ga'

export default {
  name: 'ReservationsListDialog',
  components: {ReservationsListItem, Modal},
  props: ['id', 'cartId'],
  data() {
    return {
      show: false,
      items: [],

      loading: false,
      error: null,
      success: null,
    }
  },
  methods: {
    open() {
      this.show = true
      this.fetch()
    },

    close() {
      this.show = false
      this.items = []
    },

    fetch() {
      this.loading = true
      this.error = null
      this.success = null

      return Api.get(`/Purchases/${this.id}/Reservations`).then(r => {
        this.items = r.data
        this.loading = false
      }).catch(e => {
        this.error = e.message
        this.loading = false
      })
    },

    deleteItem(rid) {
      const item = find(this.items, {Id: rid})
      const proceed = confirm(`Вы точно хотите удалить бронь \u00ab${item.Name}\u00bb?`)
      if (!proceed) {
        return
      }

      this.loading = true
      this.error = null
      this.success = null
      Api.delete(`/Reservations/${rid}`).then(() => {
        this.loading = false
        this.$emit('deleted')

        logFeatureUse(ACTION_RESERVATION_DELETE)

        return this.fetch()
      }).catch(e => {
        this.error = e.message
        this.loading = false
      })
    },

    exportToSima(rid) {
      this.loading = true
      this.error = null
      this.success = null
      Api.post(`/SendReservation`, {ReservationId: rid}).then(r => {
        this.loading = false
        this.success = 'Товары успешно отправлены в корзину на sima-land.ru.'

        logFeatureUse(ACTION_FILL_SIMA_CART, 'Single reservation')

        const report = r.data
        if(report.BouncedItems.length > 0) {
          this.$emit('show-report', report)
        }
      }).catch(e => {
        this.error = e.message
        this.loading = false
      })
    },

    appendCartToReservation(rid) {
      this.loading = true
      this.error = null
      this.success = null
      Api.post(`/AppendCartToReservation`, {ReservationId: rid, CartId: this.cartId}).then(r => {
        this.loading = false
        this.success = 'Товары из корзины добавлены к брони.'

        this.$emit('appended')
      }).catch(e => {
        this.error = e.message
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
