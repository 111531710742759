<template>
  <div>
    <div class="input-group group-header" v-if="!isDefaultGroup && point">
      <div class="group-header-text input-group-text input-group-prepend align-items-baseline">
        {{point.DisplayName}} <small class="ml-1" v-if="point.Address">({{point.Address}})</small>
      </div>
    </div>
    <ul class="userlist">
      <ParticipantItem :purchase="purchase" :cart-id="cartId" :participant="pp" :idx="i"
                       :key="pp.Id" v-for="(pp, i) in participants"/>
    </ul>
  </div>
</template>

<script>
  import ParticipantItem from '@/components/ParticipantList/ParticipantItem'
  import find from 'lodash/find'

  export default {
    name: 'ParticipantGroup',
    components: {ParticipantItem},
    props: ['purchase', 'cartId', 'participants', 'points', 'pointId'],
    computed: {
      pickupPointId() {
        return parseInt(this.pointId)
      },
      isDefaultGroup() {
        return this.pickupPointId === 0
      },
      point() {
        return find(this.points, {Id: this.pickupPointId})
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "src/styles/variables";

  .group-header {
    margin-top: -1px;

    &-text {
      padding-left: 1rem;
      flex-grow: 1;
      margin-right: 0; // Reset the -1px offset.
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;

      background: $bg-pickup;
      color: $gray-100;
    }
  }
</style>
