<template>
  <div>
    <template v-if="hasPickupPoints">
      <ParticipantGroup :purchase="purchase" :cart-id="cartId" :participants="group" :point-id="id"
                        :points="pickupPoints"
                        :key="id" v-for="(group, id) in groupedParticipants"/>
    </template>
    <template v-else>
      <ul class="userlist">
        <ParticipantItem :purchase="purchase" :cart-id="cartId" :participant="pp" :idx="i"
                         :key="pp.Id" v-for="(pp, i) in purchase.Participants"/>
      </ul>
    </template>
  </div>
</template>

<script>
  import Api from '@/api'
  import {mapGetters} from 'vuex'
  import ParticipantItem from '@/components/ParticipantList/ParticipantItem'
  import groupBy from 'lodash/groupBy'
  import ParticipantGroup from '@/components/ParticipantList/ParticipantGroup'

  export default {
    name: "PurchaseParticipantList",
    components: {ParticipantGroup, ParticipantItem},
    props: ['purchase', 'cartId'],

    created() {
      Api.get(`/Purchases/${this.purchase.Id}/PickupPoints`).then(r => {
        this.pickupPoints = r.data
      })
    },

    data() {
      return {
        pickupPoints: [],
      }
    },

    computed: {
      hasPickupPoints() {
        return this.pickupPoints.length > 0
      },

      groupedParticipants() {
        return groupBy(this.purchase.Participants, 'PickupPointId')
      },

      ...mapGetters({
        showParticipantInvoice: 'participantList/showParticipantInvoice',
        showAssemblyShipping: 'assembly/isAssemblyMode',
      })
    },
  }
</script>

<style lang="scss">
  .userlist {
    margin: 0;
    padding: 0;

    list-style: none;
  }
</style>
