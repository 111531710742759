import { render, staticRenderFns } from "./NewPointEditor.vue?vue&type=template&id=d77e8360&scoped=true&"
import script from "./NewPointEditor.vue?vue&type=script&lang=js&"
export * from "./NewPointEditor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d77e8360",
  null
  
)

export default component.exports