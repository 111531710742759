<template>
  <Modal title="Сборка товара" size="lg" ref="modal" v-if="show" @closed="close">
    <template v-slot:body>
      <table class="table mb-0">
        <thead>
          <tr>
            <th>Участник</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
              :class="[{
                'assembly-status__assembled': pp.AssemblyStatus > 0
              }]"
              :key="pp.Id" v-for="pp in participants">
            <td>
              {{pp.DisplayName}}
              <span class="badge badge-pill item-count" :class="[pp.IsReserved? 'badge-danger' : 'badge-info']">
                {{pp.Volume}}
              </span>
              <small class="text-nowrap text-info ml-1" v-if="pp.AssemblyStatus > 0">
                Собрано.

                <template v-if="pp.AssemblyStatus === 1 && pp.AssemblyNote">
                  ({{pp.AssemblyNote}})
                </template>
              </small>
            </td>
            <td class="text-right">
              <button class="btn btn-info" @click="toggleAssemblyStatus(pp)">
                <template v-if="pp.AssemblyStatus > 0">
                  Разобрать
                </template>
                <template v-else>
                  Собрать
                </template>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <template v-slot:footer>
      <div class="spinner-border mr-2" role="status" v-if="loading"></div>
      <div class="text-danger" v-if="error">{{error}}</div>
      <button type="button" class="btn btn-info" @click="toggleAll">
        {{toggleAllDirection === 'assembled' ? 'Собрать' : 'Разобрать'}} всех
      </button>
    </template>
  </Modal>
</template>

<script>
  import Api from '@/api'
  import Modal from '@/components/Modal'
  import mixinUser from '@/util/mixin-user'
  import {
    ACTION_PURCHASE_ASSEMBLY_WINDOW,
    ACTION_PURCHASE_ASSEMBLY_WINDOW_TOGGLE_ALL,
    ACTION_PURCHASE_ASSEMBLY_WINDOW_TOGGLE_PARTICIPANT,
    logFeatureUse
  } from '@/util/ga'
  import {mapActions} from 'vuex'
  import has from 'lodash/has'
  import find from 'lodash/find'
  import {STATUS_ASSEMBLED, STATUS_ASSEMBLY_NOTE, STATUS_NOT_ASSEMBLED} from '@/store/modules/assembly'

  const statusStates = {} // State table.
  statusStates[STATUS_NOT_ASSEMBLED] = STATUS_ASSEMBLED
  statusStates[STATUS_ASSEMBLED] = STATUS_NOT_ASSEMBLED
  statusStates[STATUS_ASSEMBLY_NOTE] = STATUS_NOT_ASSEMBLED

  const TOGGLE_ALL_DIRECTION_ASSEMBLED = 'assembled'
  const TOGGLE_ALL_DIRECTION_NOT_ASSEMBLED = 'not-assembled'

  export default {
    name: 'AssembleItemDialog',

    components: {Modal},

    mixins: [mixinUser],

    props: ['id'],

    data() {
      return {
        article: '',

        show: false,

        loading: false,
        error: null,
      }
    },

    computed: {
      participants() {
        return this.$store.getters['itemParticipants/participants'](this.id, this.article)
      },

      toggleAllDirection() {
        if (find(this.participants, {AssemblyStatus: 0})) {
          return TOGGLE_ALL_DIRECTION_ASSEMBLED;
        }

        return TOGGLE_ALL_DIRECTION_NOT_ASSEMBLED;
      }
    },

    methods: {
      open({article} = {}) {
        this.article = article || ''
        this.show = true

        this.loading = true
        this.fetchArticleParticipants({pid: this.id, articleNumber: this.article}).then(() => {
          this.loading = false

          logFeatureUse(ACTION_PURCHASE_ASSEMBLY_WINDOW)
        }).catch(e => {
          this.error = e.message
        })
      },

      close() {
        this.article = ''
        this.show = false
        this.$emit('closed')
      },

      toggleAssemblyStatus(pp) {
        let newState = statusStates[pp.AssemblyStatus]

        if(!has(statusStates, newState)) {
          newState = STATUS_NOT_ASSEMBLED
        }

        logFeatureUse(ACTION_PURCHASE_ASSEMBLY_WINDOW_TOGGLE_PARTICIPANT)

        this.loading = true
        this.error = null
        Api.post(`/Assembly/${pp.Id}`, {Status: newState}).then(() => {
          this.loading = false
          pp.AssemblyStatus = newState
          this.$emit('assembly-changed')
        }).catch((e) => {
          this.loading = false
          this.error = e.message
        })
      },

      toggleAll() {
        const dir = this.toggleAllDirection

        logFeatureUse(ACTION_PURCHASE_ASSEMBLY_WINDOW_TOGGLE_ALL)

        this.loading = true
        this.error = null
        Api.post(`/Assembly/ToggleAll`, {
          PurchaseId: this.id,
          ArticleId: this.article,
          Direction: dir,
        }).then(() => {
          return this.fetchArticleParticipants({pid: this.id, articleNumber: this.article})
        }).then(() => {
          this.loading = false
          this.$emit('assembly-changed')
        }).catch(e => {
          this.error = e.message
        })
      },

      ...mapActions({
        fetchArticleParticipants: 'itemParticipants/fetchPurchaseItem'
      })
    }
  }
</script>

<style lang="scss" scoped>
  @import "src/styles/item-styles";
</style>
