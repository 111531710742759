<template>
  <div class="custom-control custom-checkbox">
    <input type="checkbox" class="custom-control-input" :id="`pickupPoint-${item.Id}`" :value="item.Id"
           @change="$emit('input', checkedProxy)" v-model="checked">
    <label class="custom-control-label" :for="`pickupPoint-${item.Id}`">
      {{item.DisplayName}} <small class="text-muted">{{item.Address}}</small>
    </label>
  </div>
</template>

<script>
  export default {
    name: 'PickupPoint',
    props: ['value', 'item'],
    data () {
      return {
        checkedProxy: false
      }
    },
    computed: {
      checked: {
        get() {
          return this.value
        },
        set(v) {
          this.checkedProxy = v
        }
      }
    }
  }
</script>

<style scoped>

</style>
